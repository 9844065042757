import React from 'react';

export default function Main({ children, isStorybook }: { children: React.ReactNode; isStorybook?: boolean }) {
    return (
        <main
            className={'block min-height-100dvh width-100'}
            // eslint-disable-next-line no-inline-styles/no-inline-styles
            style={{ minHeight: isStorybook ? 'unset' : undefined, overflowX: 'clip' }}
        >
            {children}
        </main>
    );
}
